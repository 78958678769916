// import logo from './logo.svg';
import './App.css';
import Lottie from 'lottie-react';
import underConstruction from '../src/assests/images/underConstruction3.json';


function App() {
  return (
    <>
    <div className="d-flex justify-content-center">
      <div className='AnnimationContainer'>
          <Lottie className='animationdata' animationData={underConstruction} loop={true} />
          <h1>Pluck a Fish</h1>
          <h2>We are renovating our site. Do check back after a few days.</h2>
      </div>
    </div>
    </>
  );
}

export default App;
